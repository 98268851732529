module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-optin/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-GV1G3EEMPC"],"gtagConfig":{"cookie_expires":31536000},"pluginConfig":{"debug":false,"head":true,"respectDNT":true,"exclude":[],"delayOnRouteUpdate":0,"optinKey":"weblica-analytics-consent"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
