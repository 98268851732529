import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'

import config from "./data/config"

// ---
if (config.uiFlags.useConfigFormat) {
  dayjs.extend(require('dayjs/plugin/updateLocale'))
}
dayjs.extend(require("dayjs/plugin/localeData"))
dayjs.extend(require("dayjs/plugin/advancedFormat")) // needs to be loaded before localizedFormat - chaining
dayjs.extend(require("dayjs/plugin/localizedFormat"))

const lngKeys = Object.keys(config.languages)

let translations = {}
lngKeys.forEach(l => {
  const lng = config.languages[l]
  if (lng.available) {
    translations[l] = {
      translation: require(`./data/i18n/translation_${l}.js`)
    }

    require(`dayjs/locale/${l}`)

    // refresh locale with custom formats and ordinal function
    const localeObject = {
      ordinal: lng.ordinal
    }

    if (config.uiFlags.useConfigFormat) {
      localeObject.formats = {
        "L": lng.dateFormat,
        "LL": lng.dateFormatNumbers,
        "LLL": lng.dateFormatAlt,
        "LLLL": lng.dateFormatISO,
        "LT": lng.timeFormat
      }
    }
    dayjs.updateLocale(l, localeObject)
  }
})

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    resources: translations,
    // ns: "translation",
    fallbackLng: config.defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

// ---
export default i18n