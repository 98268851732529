
import React from 'react'
import PropTypes from "prop-types"

import config from "./data/config.js"

import { currentYear } from './data/currentYear.js'
const current = require(`./data/current-${currentYear}.js`)

const DataContext = React.createContext({
	config: config,
	current: current
})

export const DataProvider = ({ children }) => {
	return (
		<DataContext.Provider value={{
			config: config,
			current: current
		}}>
			{children}
		</DataContext.Provider>
	)
}

DataProvider.propTypes = {
	children: PropTypes.any
}

export const useDataContext = () => React.useContext(DataContext)